import React from 'react';
import '../../styles/gst.css';
import Testimonial from '../../components/Testimonial';
import FAQAccord from '../../components/GFlush';
import Layout from '../../components/Layout';
import { Seo } from '../../components/seo';
import Counter from '../../components/Counter';
import Guidance from '../../components/GSTTemp/Guidance';
// import Hero from "../../components/Hero";
import AboutWithForm from '../../components/GSTTemp/AboutWithForm';
import ScrollNav from '../../components/GSTTemp/ScrollNav';
import ImgContent from '../../components/GSTTemp/ImgContent';
import ContentForm from '../../components/GSTTemp/ContentForm';
import Cta from '../../components/Cta';
import SliderContent from '../../components/GSTTemp/SliderContent';
import ContentImg from '../../components/GSTTemp/ContentImg';
import Package from '../../components/GSTTemp/Package';

export default function NGORegistration() {
  /* Slider */
  // const trustSlider = [
  //   {
  //     header_color: false,
  //     content_color: false,
  //     marginTop: 30,
  //     background: `linear-gradient(139.4deg, rgb(252, 237, 216) 28.54%, rgb(245, 197, 198) 104.43%)`,
  //     header: `Online Trust Registration`,
  //     content: `Secure Trust Registration.`,
  //     image: "/imgs/registration/msme/msme-doc.png",
  //     alt_tag: "Online Trust Regstration Near Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Trust Registration`,
    buyBtnLink: `#pricing-buy`,
    price: `9999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: "/imgs/icons/gst.png",
        alt_tag: "PAN & TAN Registration",
        background: "",
        plus: "+",
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: "/imgs/icons/itr_filing.png",
        alt_tag: "TODAYFILINGS ITR E-filing service",
        background: "bg-f78acb",
        plus: "+",
        plus_dnone: "d-none d-md-block",
      },
      {
        points: `MSME Registration`,
        icon: "/imgs/icons/enterprises.png",
        alt_tag: "MSME Registration",
        background: "bg-eb6b3d",
        plus: "+",
      },
      {
        points: `Tax Consultancy Service `,
        icon: "/imgs/icons/consultant.png",
        alt_tag: "Tax Consultancy Service",
        background: "bg-c679e3",
      },
    ],
  };
  /* About Data With Form*/
  const trustAboutData = {
    header: `Online Trust Registration`,
    sub_title: `Trust registration with the TODAYFILINGS's professional Staffs.`,
    content_paragraph: [
      // `Creating a trust deed is the first step or prerequisite in the trust registration online procedure. A trust deed must be
      // registered in order to be a charitable trust. `,

      `Booking an appointment with the relevant sub-registrar office is the very next step after the Trust deed registration draught 
      has been completed. Importantly, in order to complete the registration process, all of the trustees must appear with the sub-registrar 
      on the appointed date, together with the trust deed and two witnesses.`,
    ],
  };

  /* Scroll Nav Data */
  const trustScrollId = [
    {
      id: `#documents`,
      heading: `Documents`,
    },
    {
      id: `#procedure`,
      heading: `Procedure`,
    },
    {
      id: `#typess`,
      heading: `Types`,
    },
    {
      id: `#advantage`,
      heading: `Advantages`,
    },
  ];

  /* Img Content Component Data */
  const trustIcData = {
    id: 'documents',
    mt_div: '',
    mt_img: '',
    header: 'Required Documents For NGO Registration',
    image: '/imgs/registration/ngo/trust.png',
    alt_tag: 'non-government organization',
    points: [
      {
        head: `Trust registration documents`,
        content: `The following documents are required for NGO trust registration under "The Indian Trust Act, 1882."`,
        icon: true,
      },
      {
        head: '',
        content: `Driving License.`,
        icon: true,
      },
      {
        head: '',
        content: `Passport.`,
        icon: true,
      },
      {
        head: '',
        content: `A bill of water and electricity`,
        icon: true,
      },
      {
        head: '',
        content: `The identity proof of a minimum of two members of the entity.`,
        icon: true,
      },
    ],
  };

  /* Content Form Component Data */
  const trustCfData = {
    id: 'procedure',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'NGO Trust Registration Procedures',
    body: [
      {
        head: `Registration of an NGO Trust`,
        points: ``,
        icon: false,
      },
      {
        head: `Step 1:`,
        points: `The applicant must complete all required fields on the straightforward application 
        form known as the NGO Registration Form.`,
        icon: true,
      },
      {
        head: `Step 2:`,
        points: `Assemble the Prerequisites Information. Here, you must gather all necessary paperwork 
        in accordance with the requirements. It also takes about two working days.`,
        icon: true,
      },
      {
        head: `Step 3:`,
        points: `Compile a trust deed in accordance with the information you provided in your registration 
        forms. The duration of this process is 4 to 5 working days.`,
        icon: true,
      },
      {
        head: 'Step 4',
        points: `The trust deed will be presented to the relevant local registrar for registration as part of 
        the trust deed registration process. This process takes about 12–13 working days to finish.`,
        icon: true,
      },
      {
        head: 'Step 5:',
        points: `A registration certificate for a non-governmental organization is given. You will receive the 
        registered Trust Deed through courier after the Trust NGO registration process is complete.`,
        icon: true,
      },
    ],
  };

  /*  Content Img Component Data */
  const trustCiData = {
    id: 'advantage',
    background: 'main-content',
    mt_div: '',
    header: 'Advantages of Trust Registration',
    paragraph: ``,
    points: [
      {
        content: `Any trust is established with the intention of working on charity endeavors while also allowing 
        you to benefit from tax exemption. Alternatively referred to as a non-profit organization, these charity 
        trusts.`,
        icon: true,
      },
      {
        content: `Trust becomes a legal entity and is now eligible for all of the government's trust-related 
        advantages. Such businesses must register as charitable trusts under the Trust Act and Federal statutes.`,
        icon: true,
      },
      {
        content: `In India, the Departments of Federal and State Law offer a range of resources to manage and 
        regulate charitable organizations and the general public.`,
        icon: true,
      },
      {
        content: `Making donors into trustees, which provides tax benefits, considerably aids persons who are 
        willing to gift their property and assets to trusts.`,
        icon: true,
      },
    ],
    image: '/imgs/registration/msme/msme-img.png',
    alt_tag: 'Intellectual property dispute',
  };

  /* ImgSlider Data */
  const trustImages = [
    '/imgs/registration/ngo/trust.png',
    '/imgs/registration/msme/msme-reg.png',
    '/imgs/registration/shop/shop-img-slide.png',
  ];
  /*  Slider Content Component Data */
  const trustSCData = {
    id: 'typess',
    background: [],
    mt_div: 'mt-5',
    header: 'Types Of Trusts',
    paragraph: `According to the Indian Trust Act of 1882, a trust is a legal entity in which the owner, 
    or trustor, decides to assign the authority and control over his property or asset to a third party, 
    called the trustee, so that the beneficiary, or third party, might benefit from it.`,
    content: [
      {
        points: `The property is transferred to the trustee by the trust registry along with a 
        declaration that the trustee shall own it on behalf of the trust's beneficiaries.`,
        icon: true,
      },
      {
        head: 'Categories of trust',
        points: `Public Trust`,
        icon: true,
      },
      {
        head: '',
        points: `Private Trust`,
        icon: true,
      },
    ],
    images: trustImages,
  };

  var FAQ_data = [
    {
      header: 'What conditions must be met in order to register a trust deed?',
      body: [
        {
          content: `On stamp paper with the necessary stamp fee, a trust deed.`,
          icon: true,
        },
        {
          content: `Passport-size photo and ID showing current address.`,
          icon: true,
        },
        {
          content: `Two trustees' passport-size photos and proofs of their identities.`,
          icon: true,
        },
        {
          content: `Size of passport and two witnesses' identity documents.`,
          icon: true,
        },
      ],
    },

    {
      header: 'What does a trust deed contain?',
      body: [
        {
          content: `The name clause, registered office clause, and other terms and
          conditions are just a few of the clauses that make up a trust
          deed.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Is registering a trust a requirement?',
      body: [
        {
          content: `A prepared trust deed is not legally required for strict or
          benevolent trusts, social activities, or organizations. However,
          from a logical standpoint, it is always acceptable for charity
          trusts to have a legally recognized trust deed.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Who is able to draught trust deeds?',
      body: [
        {
          content: `When the creator or settlor transfers any property to the trustee
          for use in serving the trust's beneficiary, the trust is said to
          have begun. A trust typically consists of three parties: the
          settlor, the trustee, and the trustee's beneficiary.`,
          icon: false,
        },
      ],
    },
    {
      header: ' Is it mandatory to register for Trust? ',
      body: [
        {
          content: `Trust registration is still not considered a legal compulsion for a private trust that has a Will.
           In the case of a Public trust, whether in relation to moveable or immovable property & whether created under 
           a will or inter vivos, trust registration is optional.`,
          icon: false,
        },
      ],
    },
    {
      header: ' How many types of trust registration are there? ',
      body: [
        {
          content: `A trust can be created via a trust deed. Presently, two types of Trust exist in India, mainly public 
          trust and public trust.`,
          icon: false,
        },
      ],
    },
    {
      header: ' How is Trust formed in India? ',
      body: [
        {
          content: `A trust may be formed by any person who is competent to contracts: This includes a person, HUF, AOP, 
          establishment, so on and so forth. If a trust is formed on or behalf of an under-aged person, then the consent 
          of a principal civil court of original is required.`,
          icon: false,
        },
      ],
    },
    {
      header: ' What is the fundamental purpose of Trust? ',
      body: [
        {
          content: `Trusts are set up to facilitate legal coverage to the trustor’s assets, ensure that such assets are 
          lawfully distributed among the designated beneficiaries, and avoid or minimize inheritance or estate taxes.`,
          icon: false,
        },
      ],
    },
    {
      header: ' Can a trustee sell trust property in India? ',
      body: [
        {
          content: `A trustee, is authorized to sell land via public domain. He cannot sell the land by private contract.`,
          icon: false,
        },
      ],
    },
    {
      header: 'Can Society be registered as a trust? ',
      body: [
        {
          content: `In Maharashtra & Gujarat, all societies must secure trust registered under the Bombay Public 
          Trusts Act, 1950. A society may lead to winding up if 3/5th of the members have the intention to do so. 
          A society is a more democratic and flexible set-up.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='Trust Registration'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />
      <Layout>
        <main id='main'>
          {/* <Hero sliders={trustSlider} /> */}

          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={trustAboutData} />

          <Counter />

          <ScrollNav scroll_data={trustScrollId} />

          <ImgContent item={trustIcData} />

          <ContentForm CFSection_data={trustCfData} />

          <SliderContent ScSection_data={trustSCData} />

          <ContentImg CISection_data={trustCiData} />

          <Cta />
          <Guidance />

          <Testimonial />
          <FAQAccord items={FAQ_data} />
        </main>
      </Layout>
    </div>
  );
}
